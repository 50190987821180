
import {
  IonFab,
  IonIcon,
  IonGrid,
  IonItem,
  IonRow,
  IonCol,
  IonLabel,
  IonFabButton,
  IonFabList,
  IonText,
  IonInput,
  IonList,
  IonButton,
  IonTextarea,
} from "@ionic/vue";
import {
  logoFacebook,
  logoTwitter,
  logoInstagram,
  chevronUpCircleOutline,
  logoWhatsapp,
  logoLinkedin,
  logoTumblr,
  mail,
} from "ionicons/icons";
import { useRouter } from "vue-router";
import * as V from "vee-validate/dist/vee-validate";
export default {
  name: "MaxahDetailedFooter",
  components: {
    IonFab,
    IonIcon,
    IonGrid,
    IonItem,
    IonRow,
    IonCol,
    IonLabel,
    IonFabButton,
    IonFabList,
    IonText,
    IonInput,
    IonList,
    IonButton,
    IonTextarea,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  data() {
    const d = new Date();
    const currentYear = d.getFullYear();
    const router = useRouter();

    const emailRules = (value: any) => {
      if (!value) return "E-mail is required.";
      else if (
        value &&
        !new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$").test(
          value
        )
      )
        return "E-mail must be valid.";
      else return true;
    };
    const subjectRules = (value: any) => {
      if (!value) return "Subject is required.";
      if (value && value.length < 20)
        return "Subject must be atleast 20 characters.";
      else return true;
    };
    const messageRules = (value: any) => {
      if (!value) return "Message is required.";
      if (value && value.length < 160)
        return "Message must be atleast 160 characters.";
      else return true;
    };
    return {
      currentYear,
      logoFacebook,
      logoTwitter,
      logoInstagram,
      chevronUpCircleOutline,
      logoWhatsapp,
      logoLinkedin,
      logoTumblr,
      mail,
      router,
      emailRules,
      subjectRules,
      messageRules,
    };
  },
  methods: {
    openTab(url) {
      window.open(url, "_blank");
    },
    mailTo() {
      const link =
        "mailto:hello@maxahsoft.com?subject=Hello%20Maxah&body=Wrtie%20your%20query%20here..&bcc=h.vora139@gmail.com";
      window.location.href = link;
    },
    goTo(page) {
      this.router.push(page);
    },
    doSubmit(ev) {
      const formData = ev;
      console.log(formData);
    },
  },
};
