<template>
  <div class="ion-padding relative">
    <h3 class="ion-text-center">
      Ready to build your custom application solution?
    </h3>

    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-xl="4" size-lg="4" size-md="4">
          <!-- <img src="/assets/maxah.svg" width="135" /> -->
          <p>
            Send us your requirements on
            <a href="mailto:hello@maxahsoft.com">hello@maxahsoft.com</a> or call
            us on
            <a href="tel:+916355275150">(635) 527 5150</a>
          </p>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-xl="4" size-lg="4" size-md="4">
          <ion-item color="transparent" button lines="none">
            <ion-text color="warning">Explore</ion-text>
          </ion-item>
          <ion-item
            color="transparent"
            button
            lines="none"
            @click="goTo('/home')"
          >
            Home
          </ion-item>
          <ion-item
            color="transparent"
            button
            lines="none"
            @click="goTo('/services')"
          >
            Services
          </ion-item>
          <ion-item
            color="transparent"
            button
            lines="none"
            @click="goTo('/industries')"
          >
            Industries
          </ion-item>
          <ion-item
            color="transparent"
            button
            lines="none"
            @click="goTo('/about-us')"
          >
            About Us
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-xl="4" size-lg="4" size-md="4">
          <ion-item color="transparent" button lines="none">
            <ion-text color="warning">Say Hello</ion-text>
          </ion-item>
          <v-form v-slot="{ errors }" @submit="doSubmit($event)">
            <!-- <p>Errors</p>
            <pre>{{ errors }}</pre> -->
            <ion-list>
              <ion-item>
                <ion-label position="stacked" color="primary">Email</ion-label>
                <v-field v-slot="{ field }" name="email" :rules="emailRules">
                  <ion-input
                    v-bind="field"
                    name="email"
                    type="email"
                    spellcheck="false"
                    autocapitalize="off"
                  ></ion-input>
                </v-field>
              </ion-item>
              <ion-text color="danger">{{ errors.email }}</ion-text>
              <v-error-message name="email" class="help is-danger" />
              <ion-item>
                <ion-label position="stacked" color="primary"
                  >Subject</ion-label
                >
                <v-field
                  name="subject"
                  v-slot="{ field }"
                  :rules="subjectRules"
                >
                  <ion-input
                    v-bind="field"
                    name="subject"
                    type="text"
                  ></ion-input>
                </v-field>
              </ion-item>
              <v-error-message name="subject" class="help is-danger" />
              <ion-item>
                <ion-label position="stacked" color="primary"
                  >Message</ion-label
                >
                <v-field
                  v-slot="{ field }"
                  name="message"
                  :rules="messageRules"
                >
                  <ion-textarea
                    v-bind="field"
                    name="message"
                    rows="4"
                  ></ion-textarea>
                </v-field>
              </ion-item>
              <v-error-message name="message" class="help is-danger" />
            </ion-list>
            <ion-row responsive-sm>
              <ion-col>
                <ion-button type="submit" expand="full">Submit</ion-button>
              </ion-col>
            </ion-row>
          </v-form>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <ion-fab horizontal="start" vertical="bottom" slot="fixed">
    <ion-fab-button>
      <ion-icon :icon="chevronUpCircleOutline"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-fab-button
        color="tumblr"
        class="social-media tumblr"
        @click="openTab('https://www.tumblr.com/blog/view/maxahsspl')"
      >
        <ion-icon :icon="logoTumblr"></ion-icon>
      </ion-fab-button>
      <ion-fab-button color="mail" class="social-media mail" @click="mailTo()">
        <ion-icon :icon="mail"></ion-icon>
      </ion-fab-button>
      <ion-fab-button
        color="whatsapp"
        class="social-media whatsapp"
        @click="
          openTab(
            'https://api.whatsapp.com/send?text=Hello%20Maxah&phone=916355275150'
          )
        "
      >
        <ion-icon :icon="logoWhatsapp"></ion-icon>
      </ion-fab-button>
      <ion-fab-button
        color="linkedin"
        class="social-media linkedin"
        @click="openTab('https://www.linkedin.com/company/maxah-sspl')"
      >
        <ion-icon :icon="logoLinkedin"></ion-icon>
      </ion-fab-button>
      <ion-fab-button
        color="instagram"
        class="social-media instagram"
        @click="openTab('https://www.instagram.com/maxahsspl/')"
      >
        <ion-icon :icon="logoInstagram"></ion-icon>
      </ion-fab-button>
      <ion-fab-button
        color="twitter"
        class="social-media twitter"
        @click="openTab('https://twitter.com/maxahsspl')"
      >
        <ion-icon :icon="logoTwitter"></ion-icon>
      </ion-fab-button>
      <ion-fab-button
        color="facebook"
        class="social-media facebook"
        @click="openTab('https://www.facebook.com/maxah.sspl')"
      >
        <ion-icon :icon="logoFacebook"></ion-icon>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
</template>

<script lang="ts">
import {
  IonFab,
  IonIcon,
  IonGrid,
  IonItem,
  IonRow,
  IonCol,
  IonLabel,
  IonFabButton,
  IonFabList,
  IonText,
  IonInput,
  IonList,
  IonButton,
  IonTextarea,
} from "@ionic/vue";
import {
  logoFacebook,
  logoTwitter,
  logoInstagram,
  chevronUpCircleOutline,
  logoWhatsapp,
  logoLinkedin,
  logoTumblr,
  mail,
} from "ionicons/icons";
import { useRouter } from "vue-router";
import * as V from "vee-validate/dist/vee-validate";
export default {
  name: "MaxahDetailedFooter",
  components: {
    IonFab,
    IonIcon,
    IonGrid,
    IonItem,
    IonRow,
    IonCol,
    IonLabel,
    IonFabButton,
    IonFabList,
    IonText,
    IonInput,
    IonList,
    IonButton,
    IonTextarea,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  data() {
    const d = new Date();
    const currentYear = d.getFullYear();
    const router = useRouter();

    const emailRules = (value: any) => {
      if (!value) return "E-mail is required.";
      else if (
        value &&
        !new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$").test(
          value
        )
      )
        return "E-mail must be valid.";
      else return true;
    };
    const subjectRules = (value: any) => {
      if (!value) return "Subject is required.";
      if (value && value.length < 20)
        return "Subject must be atleast 20 characters.";
      else return true;
    };
    const messageRules = (value: any) => {
      if (!value) return "Message is required.";
      if (value && value.length < 160)
        return "Message must be atleast 160 characters.";
      else return true;
    };
    return {
      currentYear,
      logoFacebook,
      logoTwitter,
      logoInstagram,
      chevronUpCircleOutline,
      logoWhatsapp,
      logoLinkedin,
      logoTumblr,
      mail,
      router,
      emailRules,
      subjectRules,
      messageRules,
    };
  },
  methods: {
    openTab(url) {
      window.open(url, "_blank");
    },
    mailTo() {
      const link =
        "mailto:hello@maxahsoft.com?subject=Hello%20Maxah&body=Wrtie%20your%20query%20here..&bcc=h.vora139@gmail.com";
      window.location.href = link;
    },
    goTo(page) {
      this.router.push(page);
    },
    doSubmit(ev) {
      const formData = ev;
      console.log(formData);
    },
  },
};
</script>

<style scoped>
.help.is-danger {
  color: #ff3860;
}
</style>